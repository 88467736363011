/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background: hsl(0, 0%, 93%);
  color: #333;
}

a {
  color: #0b6398;
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}
